<template>
  <v-container class="setting">
    <v-row>
      <v-col cols="12" justify="center" align="center" class="pl-3 pr-3">
        <div style="width: 100%; display: flex">
          <v-text-field
            v-model="data.phone"
            clearable
            type="number"
            :label="$t('user.phone') + ' *'"
            filled
            outlined
            color="grey lighten-1"
            required
            hide-details
            v-mask="'##########'"
          ></v-text-field>
          <v-btn
            class="text-capitalize btn_show_scanner"
            @click="showScanner = true"
            elevation="0"
            style="height: 55px !important"
          >
            <v-icon small>mdi-line-scan</v-icon>
          </v-btn>
        </div>
        <v-btn
          type="submit"
          block
          class="btn_login text-uppercase"
          :loading="loading"
          @click="checkProfile"
          v-show="data.phone"
        >
          <v-icon class="mr-3" small>mdi-magnify</v-icon>
          {{ $t("common.search") }}
        </v-btn>
        <div
          v-show="data.phone && showSearchResult"
          style="width: 100%; float: left"
          class="mt-5"
        >
          <v-text-field
            v-model="data.fullName"
            :label="$t('user.fullName') + ' *'"
            filled
            outlined
            color="grey lighten-1"
            required
            hide-details
            :disabled="!editable"
          ></v-text-field>
          <v-select
            v-model="data.gender"
            :label="$t('user.gender') + ' *'"
            filled
            outlined
            color="grey lighten-1"
            required
            :items="genderOptions"
            hide-details
            :disabled="!editable"
          ></v-select>
          <v-text-field
            v-model="data.birthdate"
            :label="$t('user.dob')"
            filled
            outlined
            color="grey lighten-1"
            hide-details
            v-mask="'##/##/####'"
            :disabled="!editable"
          ></v-text-field>
          <v-text-field
            v-model="data.identityId"
            :label="$t('user.identityId')"
            filled
            outlined
            color="grey lighten-1"
            hide-details
            :disabled="!editable"
          ></v-text-field>
          <v-text-field
            v-model="data.address"
            :label="$t('user.address')"
            filled
            outlined
            color="grey lighten-1"
            hide-details
            :disabled="!editable"
          ></v-text-field>
        </div>
        <v-btn
          type="submit"
          color="blue-grey"
          block
          class="btn_login mb-5 text-uppercase"
          :loading="loading"
          v-if="data.fullName && data.phone && data.gender && isCheckBtnClicked"
          style="margin-top: 15px"
          @click="updateProfile"
        >
          <v-icon class="mr-3" small>mdi-check</v-icon>
          {{ $t("common.confirmed") }}
        </v-btn>
      </v-col>
    </v-row>
    <qrcode-stream v-if="showScanner" @decode="onDecode"></qrcode-stream>
    <v-btn
      fixed
      @click="showScanner = false"
      v-if="showScanner"
      fab
      dark
      class="btn_close_scanner"
      style="border-radius: 50px"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { GenderType } from "@/plugins/constants";
import { QrcodeStream } from "vue-qrcode-reader";

var defaultPatientProfile = () => ({
  fullName: "",
  identityId: "",
  gender: GenderType.Male,
  phone: "",
  address: "",
  birthdate: "",
});

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  components: {
    QrcodeStream,
  },
  computed: {
    genderOptions() {
      return Object.keys(GenderType).map((key) => ({
        text: this.$t(`register.${key.toLowerCase()}`),
        value: GenderType[key],
      }));
    },
  },
  watch: {
    value(val) {
      this.renderProfile(val);
    },
  },
  data() {
    return {
      isCheckBtnClicked: false,
      showSearchResult: false,
      showScanner: false,
      loading: false,
      data: defaultPatientProfile(),
      editable: true,
    };
  },
  methods: {
    async updateProfile() {
      var patientId = this.formatPhoneNumber(this.data.phone);
      this.data.patientId = patientId;
      this.loading = true;
      if (this.networkStatus) {
        await this.$dbSet(`patients/${patientId}`, this.data);
        await this.$dbSet(`users/${this.$uid}/patients/${patientId}`, {
          fullName: this.data.fullName,
        });
        await this.subscribeProfile(this.data);
        this.$emit("input", patientId);
        this.$emit("selectedProfile", this.data);
      } else {
        this.$emit("input", patientId);
        this.$emit("selectedProfileOffline", this.data);
      }
      this.loading = false;
    },
    async subscribeProfile(patientObj) {
      if (!patientObj || !patientObj.id) return;
      var { id, phone } = patientObj;
      if (!phone || this.$phone == phone) return;
      var subscription = await this.$dbGet(`subscribe/${id}/${this.$uid}`);
      if (subscription) return;
      subscription = await this.$dbGet(`patients/${this.$phone}`);
      await this.$dbSet(`subscribe/${id}/${this.$uid}`, {
        ...subscription,
        isSubscribed: false,
        isSeen: false,
      });
    },
    async checkProfile() {
      this.isCheckBtnClicked = true;
      var patientId = this.formatPhoneNumber(this.data.phone);
      this.data.patientId = patientId;
      if (!patientId) return;
      this.showSearchResult = true;
      if (this.networkStatus) {
        console.log("Checking profile " + patientId + "...");
        var result = await this.renderProfile(patientId);
        console.log("Found:", result || null);
        if (!result) this.data = { ...defaultPatientProfile, phone: patientId };
      } else {
        this.data = { ...defaultPatientProfile, phone: patientId };
      }
    },
    async renderProfile(patientId = "") {
      if (!patientId) return false;
      this.loading = true;
      var patient = await this.$dbGet(`patients/${patientId}`);
      this.loading = false;
      if (!patient) {
        this.editable = true;
        return false;
      }
      this.data = patient;
      this.editable = false;
      return patient;
    },
    async onDecode(str) {
      if (str) {
        var phone = str.split("?PHONE=")[1];
        if (phone) {
          this.data.phone = phone;
          this.checkProfile();
        }
      }
      this.showScanner = false;
    },
    clean() {
      this.isCheckBtnClicked = false;
      this.showSearchResult = false;
      this.showScanner = false;
      this.loading = false;
      this.editable = true;
      this.data = defaultPatientProfile();
    },
  },
};
</script>

<style lang="scss"></style>
